import { CanActivateFn, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { inject } from '@angular/core';

export const featureGuard: CanActivateFn = (route, state) => {
  const feature: keyof (typeof environment.featuresFlag) = route.data['feature'];
  const router = inject(Router);

  if (environment.featuresFlag[feature]) {
    return true;
  } else {
    return router.createUrlTree(['/'])
  }
};
