<ng-container *ngTemplateOutlet="sidebarTemplate; context:{ $implicit: isScreenSmall | async, hasNotification: notifications() }" />

<ng-template #sidebarTemplate let-isSmall let-hasNotification="hasNotification">
    <mat-sidenav-container [class.has-notification]="hasNotification">
        <pd-header (menuOpened)="toggleSidenav()" />
        @if (isSmall) {
            <mat-sidenav [opened]="false" mode="over" [fixedInViewport]="true">
                <pd-nav (menuClosed)="toggleSidenav()" />
            </mat-sidenav>
        }
        @if (!isSmall && hasNotification; as notifications) {
            <div [class]="'notification ' + notifications.color">
                <i [class]="'icon-size-18 icon-' + notifications.icon"></i>
                {{notifications.message}}
            </div>
        }
        <main>
            @if (!isSmall) {
                <pd-nav />
            }
            <div class="content">
                <router-outlet />
            </div>
        </main>
    </mat-sidenav-container>
</ng-template>