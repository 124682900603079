import { environment } from 'env';

export interface MenuItem {
  id?: string;
  route: string | null;
  icon?: string;
  name: string;
  summary?: string;
  featureFlagName: keyof (typeof environment.featuresFlag);
  children?: MenuItem[];
}

export const ALL_ITEMS: MenuItem[] = [
  {
    route: '/dashboard',
    icon: 'home',
    name: 'Home',
    featureFlagName: 'home'
  },
  {
    route: '/buy-voucher',
    icon: 'ticket-alt',
    name: 'Buy Voucher',
    featureFlagName: 'buyVoucher'
  },
  {
    route: '/redeem-voucher',
    icon: 'ticket-expired',
    name: 'Redeem Voucher',
    featureFlagName: 'redeemVoucher'
  },
  {
    route: '/deposit',
    icon: 'card-receive',
    name: 'Add Money',
    featureFlagName: 'deposit'
  },
  {
    route: '/withdraw',
    icon: 'card-send',
    name: 'Send Money',
    featureFlagName: 'withdraw'
  },
  {
    route: '/vouchers',
    icon: 'ticket',
    name: 'Vouchers List',
    featureFlagName: 'vouchers'
  },
  {
    route: '/balance-history',
    icon: 'dollar-square',
    name: 'Balance History',
    featureFlagName: 'balanceHistory'
  },
  {
    route: '/crypto-transactions',
    icon: 'empty-wallet-change',
    name: 'Crypto Transaction',
    featureFlagName: 'cryptoTransactions'
  },
  {
    route: '',
    icon: 'security-user',
    name: 'Account',
    featureFlagName: 'account',
    children: [
      {
        route: '/kyc',
        name: 'Identity Verification',
        featureFlagName: 'identityVerification',
      },
      {
        route: '/security',
        name: 'Security',
        featureFlagName: 'security'
      },
    ]
  },
  {
    route: null,
    icon: 'logout-menu',
    name: 'Logout',
    featureFlagName: 'logout'
  },
];
