import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { FeatureToggleDirective } from '@shared/directives/feature-toggle.directive';
import { ALL_ITEMS } from '@shared/layout/services/menu-items';
import { StorageService } from 'src/app/pages/auth/services/storage.service';
import { BrandingComponent } from '../branding/branding.component';

@Component({
  selector: 'pd-nav',
  standalone: true,
  imports: [
    MatListModule,
    RouterLink,
    RouterLinkActive,
    BrandingComponent,
    MatButtonModule,
    FeatureToggleDirective
  ],
  templateUrl: './nav.component.html',
  styleUrl: './nav.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavComponent {
  @Output() menuClosed = new EventEmitter<void>();
  menuItems = ALL_ITEMS;

  constructor(
    private storageService: StorageService
  ) {

  }

  logout(route: string | null) {
    if (route !== null) {
      return;
    }

    this.storageService.logout();
  }
}
