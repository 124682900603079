import { Directive, ElementRef, inject, input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from '../../../environments/environment';

@Directive({standalone: true, selector: '[feature]'})
export class FeatureToggleDirective implements OnInit {
  viewContainer = inject(ViewContainerRef);
  element = inject(ElementRef);
  template = inject(TemplateRef);
  feature = input.required<keyof (typeof environment['featuresFlag'])>();

  ngOnInit() {
    if (environment.featuresFlag[this.feature()]) {
      this.viewContainer.createEmbeddedView(this.template);
    } else {
      this.viewContainer.clear();
    }
  }
}
